1<template>
  <div class="body">
    <div class="comp">
      <a-button :type="change == 0 ? 'primary' : ''" shape="round" icon="file-sync" class="font" @click="gwfs"
        >公文发送</a-button
      >
      <a-button :type="change == 1 ? 'primary' : ''" shape="round" icon="file-sync" class="font bts" @click="change = 1"
        >发送列表</a-button
      >

      <a-button :type="change == 2 ? 'primary' : ''" shape="round" icon="file-sync" class="font bts" @click="change = 2"
        >草稿箱</a-button
      >
    </div>
    <mytable v-if="change != 0" />
    <myform v-if="change == 0" />
  </div>
</template>
 


<script>
import mytable from './table.vue'
import myform from './form.vue'
export default {
  components: { mytable, myform },
  data() {
    return {
      change: 0,
    }
  },
  methods:{
    gwfs(){
      this.change = 0
      this.$router.replace({ path: '/fileStreamDocumentSend' })
    }
  }
}
</script>
<style lang="less" scoped>
.flex {
  display: flex;
  justify-content: space-between;
}
.font {
  font-size: 16px;
}

.body {
  width: 1280px;
  margin: auto;
  background: #fff;
}
.comp {
  padding: 38px 40px 0;
}
.bts {
  margin-left: 17px;
}
</style>